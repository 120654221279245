import { MdArrowForward } from 'react-icons/md'
import classNames from 'classnames'
import Calendar from '../../../../shared/components/organisms/Calendar/Calendar'
import { FormQuestionType } from '../../../../types/Forms'
import useQuestionValues from '../hooks/useQuestionValues'
import InputError from './atoms/InputError'
import useQuestionErrorMessage from '../hooks/useQuestionErrorMessage'
import { inputErrorClassNames } from './atoms/helpers'
import { getUpdatedByUuid } from '../../../../shared/utils/arrayHelpers'
import useIsQuestionDisabled from './hooks/useIsQuestionDisabled'
import useSynchronizedFormUpdate from '../hooks/useSynchronizedFormUpdate'
import { FormEvents } from '../Questions'

interface IDateQuestion {
  question: FormQuestionType
}

const DateQuestion: React.FC<IDateQuestion> = ({
  question,
}) => {
  const [questionValues, setQuestionValues] = useQuestionValues(question)
  const setQuestionDates = useSynchronizedFormUpdate(setQuestionValues)
  const isDisabled = useIsQuestionDisabled(question)
  const error = useQuestionErrorMessage(question)

  const questionUUIDs = questionValues.map((questionValue) => questionValue.__uuid)

  const handleComplete = () => FormEvents.QuestionAnswered.dispatch(question.__uuid)

  const isDateTime = question?.type === 'DATE_TIME'

  const handleChange = (date: Date) => {
    date.setHours(23, 0, 0, 0)

    const __uuid: string = questionValues[0]?.__uuid
    const newValue = { __uuid, value: date }

    if (questionUUIDs.includes(__uuid)) {
      setQuestionDates(getUpdatedByUuid(questionValues, [newValue]))
    } else {
      setQuestionDates([...questionValues, newValue])
    }

    handleComplete()
  }

  // todo localization
  const dateFormat = `dd.MM.yyyy${isDateTime ? ' HH:mm' : ''}`
  const dateSeparator = '.'

  const formattedAndSeparatedFormat = `${dateFormat?.replaceAll('.', dateSeparator)}`

  const selectedFirstDate = question?.values?.find((questionValue) => questionValue.__uuid === questionValues[0]?.__uuid)?.value
    ? new Date(question?.values?.find((questionValue) => questionValue.__uuid === questionValues[0]?.__uuid)?.value as string)
    : undefined

  return (
    <div>
      <div className="flex gap-x-2 relative w-full items-center ">
        <Calendar
          disabled={isDisabled}
          onChange={handleChange}
          selected={selectedFirstDate}
          showTimeSelect={isDateTime}
          className={classNames(
            { [inputErrorClassNames]: error, 'bg-gray-100': isDisabled },
          )}
          dateFormat={formattedAndSeparatedFormat}
          placeholder={formattedAndSeparatedFormat.toUpperCase()}
        />
      </div>
      <InputError error={error} />
    </div>
  )
}

export default DateQuestion
